import { Auth } from '@aws-amplify/auth';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { Form, Header1, Header2, Hr, Link, MarginBottom, PCenter, SubmitBox } from '../styles/Common.styles';
import { LINKS } from '../utils/constants';
import useTimedState from '../utils/hooks/use-timed-state';
import Alert from './Alert';
import { AlertType } from './Alert/types';
import Input from './forms/Input';
import SubmitSpinner from './forms/SubmitSpinner';
import Layout from './templates/Layout';

export type FormData = {
  code: string;
};

const schema = yup.object().shape({
  code: yup
    .string()
    .label('Verfication Code')
    .min(6, 'Verfication Code should be greater than 6 chars')
    .max(30, 'Verfication Code should be less than 30 chars')
    .required(),
});


const VerifyEmail = (): JSX.Element  => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const { email, code } = useParams<string>();
  const history = useHistory();

  const [success, setSuccess] = useTimedState<string | null>(3000, null);
  const [error, setError] = useTimedState<string | null>(3000, null);
  const [inFlight, setInFlight] = useState<boolean>(false);

  const onSubmit = ({ code }: FormData) => {
    if(!email) {
      return;
    }
    Auth.confirmSignUp(email, code)
      .then(() => {
        setSuccess('Code verified, redirecting you to login shortly');
        setTimeout(() => history.push('/login'), 1500);
      })
      .catch((err) => {
        setInFlight(false);
        setError(err.message);
      });
  };

  const resendCodeHandler = () => {
    console.log("resend code");
    Auth.verifyCurrentUserAttribute('email')
      .then(() => {
        setSuccess('Verfication code resent.');
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  if (code && email) {
    Auth.confirmSignUp(email, code)
      .then(() => {
        setSuccess('Code verified, redirecting you to login shortly');
        setTimeout(() => history.push('/'), 1500);
      })
      .catch((err) => {
        setError(err.message);
      });
  }

  return (
    <Layout>
      <Header1>Check your email</Header1>
      <Header2>We've sent you a verification code</Header2>
      <>{error && <Alert type={AlertType.Error} message={error} />}</>
      <>{success && <Alert type={AlertType.Success} message={success} />}</>
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete="on">

      
      <>{!code && 
            <><MarginBottom>
              <Input name="Verfication Code" id="code" register={register} errors={errors} autocomplete="name" />
            </MarginBottom>
            <SubmitBox>
              <button disabled={inFlight}>
                {inFlight && <SubmitSpinner />}
                {!inFlight && 'Sign In'}
              </button>
            </SubmitBox></>
          
        }</>
        
        <>{code && <Header1>Curently processing code....</Header1>}</>
        <Hr />
        <PCenter>
          <Link onClick={resendCodeHandler} href="#">Resend code?</Link>
        </PCenter>
        <PCenter>
          <Link href={LINKS.LOGIN_URL}>Already verified? Login here</Link>
        </PCenter>
      </Form>
    </Layout>
  );
};

export default VerifyEmail;
