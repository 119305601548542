import { Auth } from '@aws-amplify/auth';
import { yupResolver } from '@hookform/resolvers/yup';
import { default as React, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Form, Header1, Header2, Hr, Link, MarginBottom, PCenter, SubmitBox } from '../styles/Common.styles';
import { LINKS } from '../utils/constants';
import useTimedState from '../utils/hooks/use-timed-state';
import Alert from './Alert';
import { AlertType } from './Alert/types';
import Input, { InputType } from './forms/Input';
import SubmitSpinner from './forms/SubmitSpinner';
import Layout from './templates/Layout';

export type FormData = {
  email: string;
  password: string;
};

const schema = yup.object().shape({
  email: yup.string().label('Email').email().required(),
  password: yup.string().required(),
});

const Login = (): JSX.Element => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const history = useHistory();

  const [success, setSuccess] = useTimedState<string | null>(3000, null);
  const [error, setError] = useTimedState<string | null>(3000, null);
  const [inFlight, setInFlight] = useState<boolean>(false);

  const onSubmit = ({ email, password }: FormData) => {
    setInFlight(true);
    console.log(email, password)
    Auth.signIn(email, password)
      .then(() => {
        setSuccess('Redirecting you to login shortly');
        setTimeout(() => history.push('/success'), 1500);
      })
      .catch((err) => {
        setInFlight(false);
        setError(err.message);
      });
  };

  return (
    <Layout>
      <Header1>Sign In</Header1>
      <Header2>Please enter your email address and password.</Header2>
      <>{error && <Alert type={AlertType.Error} message={error} />}</>
      <>{success && <Alert type={AlertType.Success} message={success} />}</>
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete="on">
        <MarginBottom>
          <Input
            name="Email"
            id="email"
            register={register}
            errors={errors}
            autocomplete="email"
            inputType={InputType.Email}
            disabled={inFlight}
          />
        </MarginBottom>
        <MarginBottom>
          <Input
            name="Password"
            id="password"
            register={register}
            errors={errors}
            autocomplete="password"
            inputType={InputType.Password}
            disabled={inFlight}
          />
        </MarginBottom>
        <SubmitBox>
          <button disabled={inFlight}>
            {inFlight && <SubmitSpinner />}
            {!inFlight && 'Sign In'}
          </button>
        </SubmitBox>
        <Hr />
        <PCenter>
          <Link href={LINKS.FORGOTTEN_PASSWORD}>Forgotten password? Reset here</Link>
        </PCenter>
        <PCenter>
          <Link href={LINKS.SIGNUP}>Not yet got an account? Signup here</Link>
        </PCenter>
      </Form>
    </Layout>
  );
};

export default Login;
