import React, { useState } from 'react';
import { FieldError } from 'react-hook-form/dist/types/errors';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { DeepMap } from 'react-hook-form/dist/types/utils';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Errors, Label } from './CountryListInput.styles';
import { IntlTelInputContainer } from './Input.styles';

interface Props {
  name: string;
  id: string;

  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: DeepMap<any, FieldError>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>;
}

type CountryData = {
  name?: string,
  countryCode?: string,
  dialCode?: number,
  format?: string
}


const CountryListInput = ({ name, id, disabled, register, errors, setValue }: Props): JSX.Element => {

  const changeHandler = (number: string, countryData: CountryData) => {
    setValue("tel", "+" + number, { shouldValidate: true });
    setValue("country", countryData.name ? countryData.name : "", { shouldValidate: true });
  }

  const [isFocused, setIsFocused] = useState<boolean>(false);
  const hasErrors = errors["tel"] || errors["country"];

  return (
    <>
      <Label htmlFor={id}>{name}</Label>
      <IntlTelInputContainer>
      <PhoneInput excludeCountries={["jp"]}
        preferredCountries={["us", "gb", "au", "ca", "de", "ie", "nl", "nz", "pl"]}
        onChange={changeHandler}
          placeholder="Select country, enter full intl number."
          inputProps={{
            required: true,
            autoComplete: "tel",
            onFocus: () => setIsFocused(true),
            onBlur: () => setIsFocused(false),
            style: hasErrors && !isFocused ? {borderColor: "red"} : {},
          }}
          disabled={disabled}
          buttonStyle={isFocused || hasErrors ? { bottom: "2px", top: "2px", left: "2px"} : {}}
        />
      </IntlTelInputContainer>
      <input type="hidden" {...register("tel")} />
      <input type="hidden" {...register("country")} />
      {errors["tel"] && <Errors>{errors["tel"].message}</Errors>}
      {errors["country"] && <Errors>{errors["country"].message}</Errors>}
    </>
  );
};

export default CountryListInput;
