import { useEffect } from 'react';

const Success = (): JSX.Element => {
  useEffect(() => {
    window.location.href = 'https://auction.integrityexports.com/start';
  }, []);
  return <></>;
};

export default Success;
