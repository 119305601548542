import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { isLoggedIn } from '../utils/auth';
import { LINKS } from '../utils/constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PrivateRoute = ({ component: Component, ...rest }: any & { component: any }): JSX.Element => {
  const [loggedIn, setLoggedIn] = useState<boolean | null>(null);
  const checkLogin = async () => {
    const checkLoginResult = await isLoggedIn();
    setLoggedIn(checkLoginResult);
  };
  useEffect(() => {
    checkLogin();
  }, []);
  return (
    <>
      {loggedIn !== null && (
        <Route
          {...rest}
          render={(props) => {
            return loggedIn ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: LINKS.LOGIN_URL,
                }}
              />
            );
          }}
        />
      )}
    </>
  );
};

export default PrivateRoute;
