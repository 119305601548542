import tw, { styled, TwStyle } from 'twin.macro';
import { AlertType } from './types';

export const backgrounds: { [key in AlertType]?: TwStyle } = {
  [AlertType.Error]: tw`bg-red-100`,
  [AlertType.Success]: tw`bg-green-200`,
  [AlertType.Warning]: tw`bg-yellow-200`,
};

export const borders: { [key in AlertType]?: TwStyle } = {
  [AlertType.Error]: tw`border-red-800`,
  [AlertType.Success]: tw`border-green-800`,
  [AlertType.Warning]: tw`border-yellow-500`,
};

export const textColors: { [key in AlertType]?: TwStyle | undefined } = {
  [AlertType.Error]: tw`text-red-800`,
  [AlertType.Success]: tw`text-green-800`,
  [AlertType.Warning]: tw`text-yellow-800`,
};

interface Props {
  type: AlertType;
}
export const Container = tw.div`w-full py-3 absolute top-0 px-3`;

export const Alert = styled.div(({ type }: Props) => [
  tw`flex flex-row items-center p-5 rounded-t border-b-2 mx-5`,
  backgrounds[type],
  borders[type],
]);

export const IconBorder = styled.div(({ type }: Props) => [
  tw`flex items-center  border-2  justify-center h-10 w-10 flex-shrink-0 rounded-full`,
  backgrounds[type],
  borders[type],
]);

export const AlertContent = tw.div` ml-4`;
export const AlertTitle = styled.p(({ type }: Props) => [tw`font-semibold text-lg`, textColors[type]]);

export const AlertMessage = styled.p(({ type }: Props) => [tw`text-sm`, textColors[type]]);
