import React from 'react';
import { Container, LeftSide, Logo, RightSide } from './Layout.styles';

interface Props {
  children: JSX.Element[] | JSX.Element;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Layout = ({ children }: Props): JSX.Element => {
  return (
    <>
      <Container>
        <div>
          <div>
            <LeftSide
              style={{
                backgroundImage: `url('https://integrityexports.com/wp-content/uploads/2015/10/P1000076.jpg')`,
              }}
            >
              <Logo>
                <img
                  src="https://integrityexports.com/wp-content/uploads/2016/04/logo-v2-retina.png"
                  alt="Integrity Exports"
                />
              </Logo>
            </LeftSide>
            <RightSide>{children}</RightSide>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Layout;
