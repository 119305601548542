import { Alert as StyledAlert, AlertContent, AlertMessage, AlertTitle, Container, IconBorder } from './Alert.styles';
import Svg from './Svg';
import { AlertType } from './types';

interface Props {
  message: string | null;
  type: AlertType;
}

const Alert = ({ type, message }: Props): JSX.Element => (
  <Container>
    <StyledAlert type={type}>
      <IconBorder type={type}>
        <Svg type={type} />
      </IconBorder>
      <AlertContent>
        <AlertTitle type={type}>{AlertType[type]}</AlertTitle>
        <AlertMessage type={type}>{message}</AlertMessage>
      </AlertContent>
    </StyledAlert>
  </Container>
);

export default Alert;
