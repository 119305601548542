import tw, { styled } from 'twin.macro';

type InputTypes = {
  error?: string | undefined;
};

export const Label = tw.label`block mb-3 text-sm font-bold text-gray-700`;
export const Select = styled.select(({ error }: InputTypes) => [
  tw`form-select block px-1 py-2 mb-1 w-full bg-gray-200 text-gray-700 rounded border-transparent border-2 focus:ring focus:border-blue-500 focus:ring-opacity-50`,
  // !error && tw`focus:border-blue-500`,
  // error && tw`border-red-500`,
]);
export const Errors = tw.p`text-xs text-red-500`;
