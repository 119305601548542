import tw, { styled } from 'twin.macro';

export const Container = styled.div({
  ...tw`container mx-auto`,
  '> div': tw`flex flex-wrap justify-center px-0 py-0 md:px-6 md:my-12`,
  '> div > div': tw`w-full xl:w-3/4 lg:w-11/12 flex flex-wrap lg:flex-nowrap`,
});

export const LeftSide = tw.div`w-full h-auto bg-gray-400 block lg:w-5/12 bg-cover md:rounded-t-lg lg:rounded-l-lg`;
export const RightSide = tw.div`relative w-full lg:w-7/12 bg-white md:rounded-b-lg lg:rounded-lg lg:rounded-l-none`;
export const Logo = styled.div({
  ...tw`w-full bg-navy rounded-t lg:rounded-t-none lg:rounded-tl`,
  '> img': tw`mx-auto object-scale-down w-3/5 p-3`,
});
