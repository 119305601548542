import tw, { styled } from 'twin.macro';

export const Header1 = tw.h1`pt-4 text-2xl text-center font-bold`;
export const Header2 = tw.h2`pt-4 text-xl text-center font-thin px-8`;
export const Hr = tw.hr`mb-4 border-t`;
export const Form = tw.form`px-8 pt-6 pb-8 mb-4 bg-white rounded`;
export const Link = tw.a`inline-block text-sm text-blue-500 align-baseline hover:text-blue-800`;

// Components
export const SubmitBox = styled.div({
  ...tw`mb-6 mt-6 flex`,
  '> button': tw`inline-flex justify-center w-full text-center px-4 py-2 font-bold text-white bg-orange rounded hover:bg-green-500 disabled:bg-green-200 focus:outline-none shadow`,
});

// styling

export const MarginBottom = tw.div`mb-2`;
export const PCenter = tw.p`text-center`;

// Grid
export const Columns2 = tw.div`grid grid-cols-2 gap-2`;
export const Column2 = tw.div`col-span-2 sm:col-span-1`;
export const Column2Full = tw.div`col-span-2`;
