import React from 'react';
import { FieldError } from 'react-hook-form/dist/types/errors';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { DeepMap } from 'react-hook-form/dist/types/utils';
import { InputBox, Label, ValidationFailureMessage } from './Input.styles';

export enum InputType {
  Email = 'email',
  Text = 'text',
  Password = 'password',
}

interface Props {
  handler?: React.FormEvent<HTMLInputElement>;
  name: string;
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  inputType?: InputType;
  placeholder?: string;
  validationOptions?: Record<string, unknown>;
  autocomplete?: string;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: DeepMap<any, FieldError>;
}

const Input = ({
  inputType = InputType.Text,
  name,
  id,
  placeholder,
  register,
  autocomplete,
  validationOptions = {},
  errors,
  disabled,
}: Props): JSX.Element => {
  return (
    <>
      <Label htmlFor={id}>{name}</Label>
      <InputBox
        {...register(id, validationOptions)}
        type={inputType}
        placeholder={placeholder}
        autoComplete={autocomplete}
        error={errors[id]}
        disabled={disabled}
      />
      {errors[id] && <ValidationFailureMessage>{errors[id].message}</ValidationFailureMessage>}
    </>
  );
};

export default Input;
