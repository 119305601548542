import { useEffect, useRef, useState } from 'react';

function useTimedState<Type>(delay: number, initialState: Type): [Type, (_: Type) => void] {
  const [state, setState] = useState<Type>(initialState);
  const existingState = useRef<Type>(initialState);

  function setTimedState(newState: Type) {
    existingState.current = state;
    setState(newState);
  }

  useEffect(() => {
    if (state === existingState.current) {
      return;
    }
    const timeout = setTimeout(() => {
      setState(existingState.current);
    }, delay);

    return () => clearTimeout(timeout);
  }, [state]);

  return [state, setTimedState];
}

export default useTimedState;
