import Auth from '@aws-amplify/auth';
import Amplify from '@aws-amplify/core';
import React from 'react';
import ReactDOM from 'react-dom';
import awsconfig from './aws-exports';
import Routes from './navigation/Routes';
import BaseStyles from './styles/BaseStyles';

/** Configure amplify */
console.log(awsconfig);
Amplify.configure(awsconfig);
Auth.configure(awsconfig);

ReactDOM.render(
  <>
    <BaseStyles />
    <Routes />
  </>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
