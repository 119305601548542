/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    aws_project_region: 'us-east-1',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_110gLxvSK',
    aws_user_pools_web_client_id: '3dg4eesr9jhdtu7jvs58nj8if8',
    oauth: {
        domain: 'integrityexports.auth.us-east-1.amazoncognito.com',
    },
    cookieStorage: {
        domain: 'localhost',
        secure: false,
        path: '/',
        expires: 365,
    },
};

export default awsmobile;
