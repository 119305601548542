import { css, Global } from '@emotion/react';
import React from 'react';
import tw, { GlobalStyles, theme } from 'twin.macro';

const customStyles = css`
  body {
    -webkit-tap-highlight-color: ${theme`colors.purple.500`};
    ${tw`antialiased bg-gray-100`}
  }
`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const BaseStyles = () => (
  <>
    <GlobalStyles />
    <Global styles={customStyles} />
  </>
);

export default BaseStyles;
