import tw, { styled } from 'twin.macro';
import { textColors } from './Alert.styles';
import { AlertType } from './types';

const SvgContainer = styled.span(({ type }: Props) => ({
  ...tw`h-6 w-6`,
  '> svg': textColors[type],
}));

interface Props {
  type: AlertType;
}

const vectors: { [key in AlertType]: string } = {
  [AlertType.Error]:
    'M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z',
  [AlertType.Success]:
    'M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z',
  [AlertType.Warning]:
    'M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z',
};

const Svg = ({ type }: Props): JSX.Element => (
  <SvgContainer type={type}>
    <svg fill="currentColor" viewBox="0 0 20 20">
      <path fillRule="evenodd" d={vectors[type]} clipRule="evenodd"></path>
    </svg>
  </SvgContainer>
);

export default Svg;
