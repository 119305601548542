import tw, { styled } from 'twin.macro';

type InputTypes = {
  error?: string | undefined;
};

export const InputBox = styled.input(({ error }: InputTypes) => [
  tw`form-input w-full px-3 py-2 mb-1 leading-tight text-gray-700 rounded border-transparent border-2 appearance-none outline-none bg-gray-200 focus:bg-white focus:outline-none focus:shadow-none`,
  !error && tw`focus:border-blue-500`,
  error && tw`border-red-500`,
]);

export const IntlTelInputContainer = styled.div({
  '.react-tel-input .form-control': tw`form-input w-full px-3 py-2 pl-12 mb-1 border-2 leading-tight rounded border-transparent bg-gray-200 focus:bg-white focus:outline-none focus:shadow-none`,
});

export const Label = tw.label`block mb-1 mt-2 text-sm font-bold text-gray-700`;

export const ValidationFailureMessage = tw.p`text-xs text-red-500`;

export const SpinnerSvg = tw.svg`animate-spin -ml-1 mr-3 h-5 w-5 text-white`;

export const SpinnerSvgCircle = tw.circle`opacity-25`;
export const SpinnerSvgPath = tw.path`opacity-75`;


