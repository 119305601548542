import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ForgotPassword from '../components/ForgotPassword';
import Login from '../components/Login';
import Logout from '../components/Logout';
import PasswordReset from '../components/PasswordReset';
import SignUp from '../components/SignUp';
import Success from '../components/Success';
import VerifyEmail from '../components/VerifyEmail';
import { LINKS } from '../utils/constants';
import PrivateRoute from './PrivateRoute';

class AppRouter extends React.Component {
  render(): JSX.Element {
    return (
      <Router>
        <>
          <PrivateRoute exact={true} path="/success" component={Success} />
          <Route exact={true} path={LINKS.LOGIN_URL} component={Login} />
          <Route exact={true} path={LINKS.SIGNUP} component={SignUp} />
          <Route exact={true} path={LINKS.FORGOTTEN_PASSWORD} component={ForgotPassword} />
          <Route exact={true} path="/verify-email/:email/:code?" component={VerifyEmail} />
          <Route exact={true} path={LINKS.LOGOUT} component={Logout} />
          <Route exact={true} path="/password-reset/:email/:code?" component={PasswordReset} />

        </>
      </Router>
    );
  }
}

export default AppRouter;
