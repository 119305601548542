import { Auth } from '@aws-amplify/auth';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

const Logout = (): JSX.Element => {
  const [loggedOut, setLoggedOut] = useState<boolean>(false);

  const logOut = async () => {
    await Auth.signOut();
    setLoggedOut(true);
  };

  useEffect(() => {
    logOut();
  }, []);

  return <>{loggedOut && <Redirect to="/" />}</>;
};

export default Logout;
