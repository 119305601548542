import { Auth } from '@aws-amplify/auth';
import { yupResolver } from '@hookform/resolvers/yup';
import { default as React, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link as NavigationLink, useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Form, Header1, Header2, Hr, Link, MarginBottom, PCenter, SubmitBox } from '../styles/Common.styles';
import { LINKS } from '../utils/constants';
import useTimedState from '../utils/hooks/use-timed-state';
import Alert from './Alert';
import { AlertType } from './Alert/types';
import Input, { InputType } from './forms/Input';
import SubmitSpinner from './forms/SubmitSpinner';
import Layout from './templates/Layout';

export type FormData = {
  email: string;
};

const schema = yup.object().shape({
  email: yup.string().label('Email').email().required(),
});

const ForgotPassword = (): JSX.Element => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const history = useHistory();
  const [success, setSuccess] = useTimedState<string | null>(3000, null);
  const [error, setError] = useTimedState<string | null>(3000, null);
  const [inFlight, setInFlight] = useState<boolean>(false);

  const onSubmit = ({ email }: FormData) => {
    Auth.forgotPassword(email)
      .then(() => {
        setSuccess('Redirecting you shortly');
        setTimeout(() => history.push(`/password-reset/${email}`), 1500);
      })
      .catch((err) => {
        setInFlight(false);
        setError(err.message);
      });
  };

  return (
    <Layout>
      <Header1>Forgotten Password</Header1>
      <Header2>Enter your email address to get a password reset link.</Header2>
      <>{error && <Alert type={AlertType.Error} message={error} />}</>
      <>{success && <Alert type={AlertType.Success} message={success} />}</>
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete="on">
        <MarginBottom>
          <Input
            name="Email"
            id="email"
            register={register}
            errors={errors}
            autocomplete="email"
            inputType={InputType.Email}
            disabled={inFlight}
          />
        </MarginBottom>
        <SubmitBox>
          <button disabled={inFlight}>
            {inFlight && <SubmitSpinner />}
            {!inFlight && 'Send password reset link'}
          </button>
        </SubmitBox>
        <Hr />
        <PCenter>
          <NavigationLink to={LINKS.LOGIN_URL}>
            <Link>I've remembered! - Log in here.</Link>
          </NavigationLink>
        </PCenter>
      </Form>
    </Layout>
  );
};

export default ForgotPassword;
