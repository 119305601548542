import { Auth } from '@aws-amplify/auth';
import { yupResolver } from '@hookform/resolvers/yup';
import { default as React, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Column2, Column2Full, Columns2, Form, Header1, Hr, Link, PCenter, SubmitBox } from '../styles/Common.styles';
import { LINKS } from '../utils/constants';
import useTimedState from '../utils/hooks/use-timed-state';
import Alert from './Alert';
import { AlertType } from './Alert/types';
import CountryListInput from './forms/CountryListInput';
import Input, { InputType } from './forms/Input';
import SubmitSpinner from './forms/SubmitSpinner';
import Layout from './templates/Layout';

export type FormData = {
  name: string;
  email: string;
  country: string;
  password: string;
  confirmPassword: string;
  tel: string;
};

const schema = yup.object().shape({
  name: yup
    .string()
    .label('Full Name')
    .min(6, 'Name should be greater than 6 chars')
    .max(30, 'Name should be less than 30 chars')
    .matches(/^[^\s]+\s[^\s]+$/, 'Must be full name seperated by a space')
    .required(),
  email: yup.string().label('Email').email().required(),
  password: yup
    .string()
    .label('Password')
    .min(8, 'Password should be greater than 8 chars')
    .matches(/[a-z]+/, 'Password must contain a lower case letter')
    .matches(/[A-Z]+/, 'Password must contain an upper case letter')
    .matches(/[0-9]+/, 'Password must contain a number')
    .required(),
  tel: yup
    .string()
    .label('Tel')
    .required()
    .min(6, 'Tel should be greater than 6 numbers')
    .max(30, 'Tel should be less than 30 numbers')
    .matches(/^\+?[0-9\-\(\)]+$/, 'Tel should contain ony numbers, + or hyphens'),
  country: yup.string().label('Country').required(),
});

const FreeTrial = (): JSX.Element => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema)

  });

  const history = useHistory();
  const [success, setSuccess] = useTimedState<string | null>(3000, null);
  const [error, setError] = useTimedState<string | null>(3000, null);
  const [inFlight, setInFlight] = useState<boolean>(false);

  const onSubmit = ({ email, password, name, tel, country }: FormData) => {
    Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        name: name,
        phone_number: tel,
        'custom:country': country,
      },
    })
      .then(() => {
        setSuccess('Redirecting you shortly, please verify your email');
        setTimeout(() => history.push(`/verify-email/${email}`), 1500);
      })
      .catch((err) => {
        setInFlight(false);
        setError(err.message);
      });
  };

  return (
    <Layout>
      <Header1>
        Explore Over 120 Car Auctions
        <br /> In Japan For 2 Weeks.
      </Header1>
      <>{error && <Alert type={AlertType.Error} message={error} />}</>
      <>{success && <Alert type={AlertType.Success} message={success} />}</>
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete="on">
        <Columns2>
          <Column2Full>
            <Input name="Full Name" id="name" register={register} errors={errors} autocomplete="name" />
          </Column2Full>
          <Column2>
            <Input
              name="Email"
              id="email"
              inputType={InputType.Email}
              register={register}
              errors={errors}
              autocomplete="email"
            />
          </Column2>
          <Column2>
            <Input
              name="Password"
              id="password"
              inputType={InputType.Password}
              register={register}
              errors={errors}
              autocomplete="new-password"
              disabled={inFlight}
            />
          </Column2>
          <Column2Full>
            <CountryListInput setValue={setValue} name="Country/Tel" id="tel" register={register} errors={errors} disabled={inFlight} />
          </Column2Full>
        </Columns2>
        <SubmitBox>
          <button disabled={inFlight}>
            {inFlight && <SubmitSpinner />}
            {!inFlight && 'Enter now'}
          </button>
        </SubmitBox>
        <Hr />
        <PCenter>
          <Link href={LINKS.LOGIN_URL}>Already have an account? Login!</Link>
        </PCenter>
      </Form>
    </Layout>
  );
};

export default FreeTrial;
